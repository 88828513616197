import React from 'react';
import '../mentoring.css';
import Card from './card';
import {useTranslation} from "gatsby-plugin-react-i18next";


function CardsGrowth({page}) {
    const {t} = useTranslation();
    let cardsContent = [
        {
            title: t(`${page}.why-to-become-1-title`),
            content: t(`${page}.why-to-become-1-text`)
        },
        {
            title: t(`${page}.why-to-become-2-title`),
            content: t(`${page}.why-to-become-2-text`)
        },
        {
            title: t(`${page}.why-to-become-3-title`),
            content: t(`${page}.why-to-become-3-text`)
        },
        {
            title: t(`${page}.why-to-become-4-title`),
            content: t(`${page}.why-to-become-4-text`)
        }
    ]


    return(
        <>
            {cardsContent?.map(_data => (
                <>
                    <Card
                        tag='h4'
                        wrapper={'dark-blue'}
                        content={true}
                        header={_data?.title}
                        headerClass={'mb-1-5rem'}
                        text={_data?.content}
                    />
                </>
            ))}
            
        
        </>
    )


}


export default CardsGrowth
import React from 'react';
import '../mentoring.css';
import Card from './card';
import {useTranslation} from "gatsby-plugin-react-i18next";


function CardsSix() {
    const {t} = useTranslation();
    let cardsContent = [
        {
            title: t('partners.cooperate-benefit-2-title'),
            cardsClass: "card-one"
        },
        {
            title: t('partners.cooperate-benefit-3-title'),
            cardsClass: "card-two"
        },
        {
            title: t('partners.cooperate-benefit-4-title'),
            cardsClass: "card-three"
        },
        {
            title: t('partners.cooperate-benefit-5-title'),
            cardsClass: "card-four"
        },
        {
            title: t('partners.cooperate-benefit-6-title'),
            cardsClass: "card-five"
        }
    ]
    
    
    
    
    
    return(
        <>
            {cardsContent?.map(_data => (
                <>
                    <Card
                        tag='h4'
                        wrapper={`white-border ${_data?.cardsClass} semi-padding`}
                        content={false}
                        header={_data?.title}
                    />
                </>
            ))}
            
        
        </>
    )


}
export default CardsSix
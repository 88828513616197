import * as React from "react";
import Layout from "../../components/layout/layout";
import Questions from "../new-mentoring/components/mentoring-questions";
import Card from "./components/card";
import MentoringPartners from "../new-mentoring/components/mentoring-partners";
import CardsGrowth from "./components/cardsGrowth";
import { useTranslation} from "gatsby-plugin-react-i18next";

import addToMailchimp from "gatsby-plugin-mailchimp";
import Slider from "react-slick";
import { Formik, Form, Field } from "formik";

import "./mentoring.css";
import "../new-mentoring/styles/why-participate.css";
import "../../styles/variables.css";
import "./styles/main.scss";
import { StaticImage } from "gatsby-plugin-image";
import CardsSix from "./components/cardsSix";
import { useState } from "react";
import ogImage from "../../images/seo/SEO_fundacja.jpg";
import Seo from "../../components/seo";
import {FullBtn} from "../new-home/components/home-buttons";

function PartnersComponent({ location }) {
  const [status, setStatus] = useState(null);
  const [error, setError] = useState(null);

  const { t } = useTranslation();

  return (
    <Layout withHeader={false}>
      <Seo
        title={t("partners.seo.title")}
        description={t("partners.seo.desc")}
        ogImage={ogImage}
      />
      <div className="mentoring-new-ctn partners">
        <section className="container become-a-partner">
          <h2 className="mb-1rem"> {t`partners.title`}</h2>
          <p className="mb-3rem">
            {t`partners.subtitle-1`} <br />
            {t`partners.subtitle-2`}
          </p>
          <div className='btn-wrapper'>
            <FullBtn to="#become-a-partner">Zostań Partnerem</FullBtn>
          </div>
        </section>
        <section className="container mb-5rem">
          <h2>{t`partners.why-to-become-title`}</h2>
          <div className="card-flex">
            <CardsGrowth page="partners" />
          </div>
        </section>
        <section className="container dark">
          <section className="container">
            <h2 className="white mb-3rem">
              {t`partners.cooperate-title-1`}
              <br />
              {t`partners.cooperate-title-2`}
            </h2>
            <div className="card-section">
              <div className="highlight">
                <Card
                  tag={"h3"}
                  wrapper={"white-border custom-padding"}
                  content={true}
                  headerClass={"mb-1rem"}
                  header={t`partners.cooperate-benefit-1-title`}
                  textClass="card-highlight"
                  text={t`partners.cooperate-benefit-1-text`}
                  extrasClass="btnCardCenter"
                  /*extras={
                    <div className="btn no-border-btn">
                      <a href={withPrefix('Oferta_Innovations_Hub_compressed.pdf')} target="_blank" rel="noreferrer nofollow">
                        {t`partners.cooperate-show-offer`}&nbsp;&nbsp;
                        <FontAwesomeIcon icon={faArrowRight} />
                      </a>
                    </div>
                  }*/
                />
              </div>
              <div className="spacer-plus">
                <StaticImage
                  src="../../images/partnerzy/Add_ring.png"
                  alt="ring"
                />
              </div>
              <div className="cards">
                <CardsSix></CardsSix>
              </div>
            </div>
          </section>
        </section>
        <MentoringPartners location={location} />
        <section className="partners-slider">
          <Slider
            className="partners-slider-ctn"
            dots={true}
            slidesToShow={1}
            slidesToScroll={1}
            infinite={true}
            autoplaySpeed={4000}
            autoplay={true}
          >
            <div className="partners-slider-item">
              <div className="partners-slider-image">
                <StaticImage
                  src="../../images/partnerzy/rajmund-martyniuk.jpg"
                  alt="Ireneusz Martyniuk"
                  loading="eager"
                  quality={100}
                  className="partner-round"
                />
              </div>
              <div className="partners-slider-text">
                <div className="p-s-testimonial">
                  <p>{t`partners.testimony-1-quote`}</p>
                </div>
                <div className="p-s-role">
                  <p>
                    {t`partners.testimony-1-author-name`} <br />
                    {t`partners.testimony-1-author-position`}
                  </p>
                </div>
              </div>
            </div>
            <div className="partners-slider-item">
              <div className="partners-slider-image">
                <StaticImage
                  src="../../images/partnerzy/ligia-kornowska.jpg"
                  alt="Ligia Kornowska"
                  loading="eager"
                  quality={100}
                  className="partner-round"
                />
              </div>
              <div className="partners-slider-text">
                <div className="p-s-testimonial">
                  <p>{t`partners.testimony-2-quote`}</p>
                </div>
                <div className="p-s-role">
                  <p>
                    {t`partners.testimony-2-author-name`}
                    <br />
                    {t`partners.testimony-2-author-position`}
                  </p>
                </div>
              </div>
            </div>
            <div className="partners-slider-item">
              <div className="partners-slider-image">
                <StaticImage
                  src="../../images/partnerzy/Grzegorz-Piestrak.jpg"
                  alt="Grzegorz Piestrak"
                  loading="eager"
                  quality={100}
                  className="partner-round"
                />
              </div>
              <div className="partners-slider-text">
                <div className="p-s-testimonial">
                  <p>{t`partners.testimony-3-quote`}</p>
                </div>
                <div className="p-s-role">
                  <p>
                    {t`partners.testimony-3-author-name`}
                    <br />
                    {t`partners.testimony-3-author-position`}
                  </p>
                </div>
              </div>
            </div>
          </Slider>
        </section>
        <section className="container partners-form" id="become-a-partner">
          <h2>{t`partners.contact-form-title`}</h2>
          <Formik
            initialValues={{
              name: "",
              company: "",
              email: "",
              phone: "",
              partnership: "",
              message: "",
            }}
            onSubmit={async (values) => {
              const result = await addToMailchimp(
                values.email,
                {
                  NAME: values.name,
                  COMPANY: values.company,
                  PHONE: values.phone,
                  PARTNER: values.partnership,
                  MSG: values.message,
                },
                "https://innovationshub.us14.list-manage.com/subscribe/post?u=017bb91f21832f1925030c7b4&amp;id=88b848acc6"
              );
              if (result.msg === "The email you entered is not valid.") {
                setStatus(null);
                setError(t(`partners.contact-form-error`));
              } else if (result.msg === "Thank you for subscribing!") {
                setError(null);
                setStatus(t(`partners.contact-form-accept`));
              }
            }}
          >
            <div className="form-wrapper">
              <Form>
                <label className="form-item-wrapper">
                  <span>{t`partners.contact-form-name`}</span>
                  <Field
                    placeholder={t`partners.contact-form-name-ph`}
                    id="name"
                    name="name"
                  />
                </label>
                <label className="form-item-wrapper">
                  <span>{t`partners.contact-form-company`}</span>
                  <Field
                    id="company"
                    name="company"
                    placeholder={t`partners.contact-form-company-ph`}
                  />
                </label>
                <label className="form-item-wrapper">
                  <span>{t`partners.contact-form-mail`}</span>
                  <Field
                    id="email"
                    className="required email"
                    name="email"
                    type="email"
                    placeholder={t`partners.contact-form-mail-ph`}
                  />
                </label>
                <label className="form-item-wrapper">
                  <span>{t`partners.contact-form-phone`}</span>
                  <Field
                    placeholder={t`partners.contact-form-phone-ph`}
                    id="phone"
                    name="phone"
                  />
                </label>
                <label className="form-item-wrapper">
                  <span>{t`partners.contact-form-scope`}</span>
                  <Field component="select" id="partnership" name="partnership">
                    <option value="MedBiz Innovations Program" default>
                      MedBiz Innovations Program
                    </option>
                    <option value="Innovations Incubator">
                      Innovations Incubator
                    </option>
                    <option value="Innovations Academy">
                      Innovations Academy
                    </option>
                    <option value="Innovations Platform">
                      Innovations Platform
                    </option>
                    <option value="Cała fundacja">{t`partners.contact-form-scope-all`}</option>
                  </Field>
                </label>
                <label className="form-item-wrapper">
                  <span>{t`partners.contact-form-text`}</span>
                  <Field
                    as="textarea"
                    rows="6"
                    placeholder={t`partners.contact-form-text-ph`}
                    id="message"
                    name="message"
                  />
                </label>
                <p className="rodo mb-2-5rem">
                  {t`partners.contact-form-rodo-1a`}
                  <a href="/rodo">{t`partners.contact-form-rodo-1b`}</a>
                  {t`partners.contact-form-rodo-1c`}
                  <a href="/rodo">{t`partners.contact-form-rodo-1d`}</a>.
                </p>
                <span>
                  {setError && <p className="error mb-1rem">{error}</p>}
                  {setStatus && <p className="status mb-1rem">{status}</p>}
                </span>
                <Field
                  name="submit"
                  className="form-button"
                  type="submit"
                  value={t`partners.contact-form-send`}
                />
              </Form>
            </div>
          </Formik>
        </section>
        <Questions location={location} />
      </div>
    </Layout>
  );
}

export default PartnersComponent;

import React, {useState, useEffect} from 'react';
import '../mentoring.css';
import '../styles/main.scss'


function Card(props) {

    const [hasContent, setIsContent] = useState(null)

    useEffect(() => {
        setIsContent(false);
        if (props.content === true) setIsContent(true)
    }, [hasContent])


    const Tag = props.tag;
    return (
        <>
            <div className={`card-wrapper ${props.wrapper}`}>
                <Tag className={props.headerClass}>{props.header}</Tag>
                {hasContent && (
                    <>
                        <p className={props?.textClass}>
                            {props?.text}
                        </p>
                        <div className={props?.extrasClass}>
                            {props?.extras}
                        </div>
                    </>
                )}
            </div>
        </>
    )


}

export default Card